import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import useTheme from '@mui/material/styles/useTheme';
import { Link } from "react-router-dom";

const prePages = ['Dashboard', 'Services'];
const postPages = ['News', 'Contact'];

function ResponsiveAppBar({ white, linearGradient }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const theme = useTheme();
  const textColor = white ? theme.palette.grey['50'] : theme.palette.grey['900'];
  const simplexColor = white ? theme.palette.grey['50'] : theme.palette.secondary.main;
  const bgColor = linearGradient ? 'linear-gradient(to bottom, rgba(0,0,0,0.6), rgba(0,0,0,0.4), rgba(0,0,0,0))' : 'transparent';

  return (
    <AppBar position="relative" sx={{ background: bgColor, boxShadow: 'none', my: 0, py: 0 }}>
      <Container maxWidth="xl" sx={{ py: 0, my: 0 }}>
        <Toolbar disableGutters sx={{ py: 3, height: '70px' }}>
          <Box sx={{ flexGrow: 1, justifyContent: 'flex-end', display: { xs: 'none', md: 'flex' } }}>
            {prePages.map((page) => (
              <Link key={page} to={`/${page.toLowerCase()}`} style={{ textDecoration: 'none' }}>
                <Button
                  key={page}
                  disabled={page === 'Dashboard'}
                  sx={{ mr: 4, color: 'black', display: 'block', textTransform: 'none' }}
                >
                  <Typography variant="body2" align="center" color={textColor} sx={{ fontSize: 16 }}>
                    {page}
                  </Typography>
                </Button>
              </Link>
            ))}
          </Box>
          <Box sx={{ display: { sm: 'flex', md: 'none' }, position: 'absolute' }}>
            <IconButton size='medium' sx={{ color: textColor }} onClick={handleClick}>
              <MenuIcon size='medium' />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              elevation={0}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              slotProps={{
                paper: {
                  style: {
                    background: white ? 'rgba(20,20,20,0.3)' : theme.palette.grey['50'],
                    pt: 2,
                    mt: 4,
                  }
                }
              }}
            >
              {prePages.map((page) => (
                <Link key={page} to={`/${page.toLowerCase()}`} style={{ textDecoration: 'none' }}>
                  <MenuItem
                    onClick={handleClose}
                    sx={{
                      px: 8,
                      py: 2,
                      textAlign: 'center',
                      alignContent: 'center',
                      background: 'transparent',
                      width: '100%',
                      color: theme.palette.grey[white ? '50' : '900'],
                      textDecoration: 'none',
                      justifyContent: "center",
                      borderBottom: 1,
                      fontWeight: 'bold'
                    }}
                    align='center'
                  >
                    {page.toUpperCase()}
                  </MenuItem>
                </Link>
              ))}
              {postPages.map((page) => (
                <Link key={page} to={`/${page.toLowerCase()}`} style={{ textDecoration: 'none' }}>
                  <MenuItem
                    onClick={handleClose}
                    sx={{
                      px: 8,
                      py: 2,
                      textAlign: 'center',
                      alignContent: 'center',
                      background: 'transparent',
                      width: '100%',
                      color: theme.palette.grey[white ? '50' : '900'],
                      textDecoration: 'none',
                      justifyContent: "center",
                      borderBottom: page === 'Contact' ? 0 : 1,
                      fontWeight: 'bold'
                    }}
                    align='center'
                  >
                    {page.toUpperCase()}
                  </MenuItem>
                </Link>
              ))}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: { sm: 'flex', md: 'none' } }} />
          <Link to={`/`} style={{ textDecoration: 'none' }}>
            <Typography
              variant="h3"
              align='center'
              sx={{
                mx: 4,
                display: 'flex',
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: simplexColor,
                textDecoration: 'none',
              }}
            >
              simplex
            </Typography>
          </Link>
          <Box sx={{ flexGrow: 1, display: { sm: 'flex', md: 'none' } }} />
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {postPages.map((page) => (
              <Link key={page} to={`/${page.toLowerCase()}`} style={{ textDecoration: 'none' }}>
                <Button
                  key={page}
                  sx={{ ml: 4, color: 'black', display: 'block', textTransform: 'none' }}
                >
                  <Typography variant="body2" align="center" color={textColor} sx={{ fontSize: 16 }}>
                    {page}
                  </Typography>
                </Button>
              </Link>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar >
  );
}
export default ResponsiveAppBar;