import useTheme from '@mui/material/styles/useTheme';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Fade } from 'react-awesome-reveal';

import Appbar from '../../components/Appbar';
import ServicesGrid from '../../components/ServicesGrid';
import Footer from '../../components/Footer';
import DashboardButton from '../../components/DashboardButton';


export default function LandingPage() {
  const theme = useTheme();
  return (
    <Box>
      <DashboardButton />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          justifyContent: 'center',
          position: 'fixed',
          left: 0,
          height: '100vh',
          width: '100vw',
          zIndex: -100,
        }}>
        <video
          src={'/toronto_f.mp4'}
          alt="toronto"
          autoplay="autoplay"
          loop="loop"
          muted="muted"
          playsinline="true"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            justifyContent: 'center',
            zIndex: -100,
            filter: 'grayscale(0.6)',
          }}
        />

      </div>
      <Box style={{ height: '100vh' }}>
        <Appbar white />
        <Box sx={{ position: 'absolute', bottom: 0, right: 0, left: 0 }}>
        </Box>
      </Box>
      <Box sx={{ p: { sm: 4, md: 6 }, background: 'linear-gradient(to bottom, #A12929, #D12F2F)' }}>
        <Container maxWidth="md" sx={{ py: 10 }}>
          <Typography variant="h3" align="center" color={theme.palette.grey['200']}>
            Maximize your digital marketing performance
          </Typography>
          <Typography variant="h3" align="center" color={theme.palette.grey['200']} sx={{ mb: 8 }}>
            <Fade>
              <b>The Simplex Way</b>
            </Fade>
          </Typography>
          <Fade bottom>
            <Typography align="center" variant="body1" color={theme.palette.grey['200']}>
              We're a brand-first marketing agency that helps your business navigate the digital advertising space.
              We aim to bring you the highest return on investment possible while creating a memorable advertising campaign.
            </Typography>
          </Fade>
        </Container>
      </Box>
      <Box sx={{ p: { sm: 4, md: 6 }, background: theme.palette.common.white }}>
        <Container maxWidth="md" sx={{ py: 10 }}>
          <Typography variant="h3" align="center" color={theme.palette.grey['900']} sx={{ mb: 8 }}>
            Discover our advertising solutions
          </Typography>
          <ServicesGrid />
        </Container>
      </Box>
      <Footer />
    </Box>
  );
}
