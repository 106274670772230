import * as React from 'react';

import Box from '@mui/material/Box';

import { Zoom } from 'react-awesome-reveal';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import useTheme from '@mui/material/styles/useTheme';

import { Link } from "react-router-dom";

const ItemWrapper = ({ title, preTitle, icon }) => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        background: theme.palette.grey['200'],
        p: 2,
      }}
    >
      <Grid
        container
        spacing={4}
        justifyContent="space-evenly"
        alignItems="stretch"
        style={{
          transition: theme.transitions.create("all", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          })
        }}
      >
        <Grid item xs={12} sm={5}>
          <Box
            sx={{
              background: theme.palette.grey['900'],
              backgroundImage: `url('${icon}')`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: '100%',
              minHeight: { xs: '200px', sm: '200px', md: '200px' }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            display="flex"
            alignItems="center"
            minHeight="100%"
            width="100%"
          >
            <Typography variant='h6' color={theme.palette.grey['900']} sx={{ fontWeight: 400 }}>
              <b style={{ color: theme.palette.secondary.main, fontWeight: 600 }}>{preTitle}</b><br />{title}
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={1}
          sx={{
            display: { xs: "none", sm: "block" }
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100%"
          >
            <ChevronRightIcon color='secondary' />
          </Box>
        </Grid>
      </Grid>

    </Box>
  );
};

export default function ServicesGrid() {
  const theme = useTheme();

  const [index, setIndex] = React.useState(null);
  return (
    <Box>
      <Grid
        container
        spacing={4}
        justifyContent="space-evenly"
        alignItems="stretch"
        style={{
          transition: theme.transitions.create("all", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          })
        }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} onMouseEnter={(e) => setIndex(1)} onMouseLeave={(e) => setIndex(null)}>
          <Link to="/news/blog/1" style={{ textDecoration: 'none' }}>
            <Zoom when={index === 1}>
              <ItemWrapper preTitle="Unlocking Growth: " title="A Journey with Simplex Media" icon="/blog1.png" />
            </Zoom>
          </Link>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} onMouseEnter={(e) => setIndex(0)} onMouseLeave={(e) => setIndex(null)}>
          <Link to="/news/blog/2" style={{ textDecoration: 'none' }}>
            <Zoom when={index === 0}>
              <ItemWrapper preTitle="Unleashing Success: " title="The Simplex Media Group Growth Partner Plan" icon="desk.jpeg" />
            </Zoom>
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
}