import * as React from 'react';

import Box from '@mui/material/Box';

import { Fade } from 'react-awesome-reveal';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';

import AutoModeIcon from '@mui/icons-material/AutoModeSharp';
import TipsAndUpdatesIcon from '@mui/icons-material/WbIncandescentOutlined';
import PriceChangeIcon from '@mui/icons-material/PriceChangeSharp';
import AbcIcon from '@mui/icons-material/AbcSharp';
import AdbIcon from '@mui/icons-material/AdbSharp';
import DevicesIcon from '@mui/icons-material/DevicesSharp';

const ItemWrapper = ({ title, children }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        background: theme.palette.grey['200'],
        p: 2
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ my: 2 }}
        color={theme.palette.secondary.light}
      >
        {children}
      </Box>

      <Typography variant='h5' align='center' sx={{ my: 2, fontWeight: 300 }}>{title}</Typography>
    </Box>
  );
};

export default function ServicesGrid() {
  const theme = useTheme();

  return (
    <Box>
      <Grid
        container
        spacing={2}
        justifyContent="space-evenly"
        alignItems="stretch"
        style={{
          transition: theme.transitions.create("all", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          })
        }}
      >
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <Fade>
            <ItemWrapper title="Digital Programmatic">
              <AutoModeIcon sx={{ width: '80px', height: '80px' }} />
            </ItemWrapper>
          </Fade>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <Fade>
            <ItemWrapper title="Creative">
              <TipsAndUpdatesIcon sx={{ width: '80px', height: '80px' }} />
            </ItemWrapper>
          </Fade>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <Fade>
            <ItemWrapper title="Paid Social">
              <PriceChangeIcon sx={{ width: '80px', height: '80px' }} />
            </ItemWrapper>
          </Fade>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <Fade>
            <ItemWrapper title="SEO">
              <AbcIcon sx={{ width: '80px', height: '80px' }} />
            </ItemWrapper>
          </Fade>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <Fade>
            <ItemWrapper title="Google Ads">
              <AdbIcon sx={{ width: '80px', height: '80px' }} />
            </ItemWrapper>
          </Fade>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <Fade>
            <ItemWrapper title="Websites">
              <DevicesIcon sx={{ width: '80px', height: '80px' }} />
            </ItemWrapper>
          </Fade>
        </Grid>
      </Grid>
    </Box>
  );
}