import * as React from 'react';

import Box from '@mui/material/Box';

import { Fade } from 'react-awesome-reveal';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';

import Stack from '@mui/material/Stack';

const ItemWrapper = ({ title, children }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        background: theme.palette.grey['200'],
        p: 4
      }}
    >
      <Stack spacing={2}>
        <Typography variant='h5' align='left' sx={{ my: 2, fontWeight: 500 }}>{title}</Typography>
        {children}
      </Stack>
    </Box>
  );
};

export default function ServicesGrid() {
  const theme = useTheme();

  return (
    <Box>
      <Grid
        container
        spacing={4}
        justifyContent="space-evenly"
        alignItems="stretch"
        style={{
          transition: theme.transitions.create("all", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          })
        }}
      >
        <Grid item sm={12} md={6}>
          <Stack spacing={4}>
            <Fade >
              <ItemWrapper title="Social Media">
                <Typography>
                  Embracing a holistic approach to Social Media, we delve deep into how it can amplify your performance across all channels.
                  At Simplex, we believe in a comprehensive strategy that leaves no stone unturned. We meticulously craft a clear blueprint
                  for expanding your account, identifying the channels that resonate most authentically with your brand, audience,
                  and products. From the dynamic realms of <b>Facebook</b> and <b>Instagram</b> to the trending platforms like TikTok, and the
                  professional landscape of <b>LinkedIn</b> to the visual allure of <b>Pinterest</b> -- we ensure that every channel is harnessed to
                  its fullest potential.
                </Typography>
                <Typography>
                  Our focus extends to your paid channels, ensuring they work tirelessly in favor of your brand. We're not just about a social
                  media presence; we're about a strategic presence that drives results. With Simplex, your rebranding journey is not just
                  a transformation, but a revolution
                </Typography>
              </ItemWrapper>

            </Fade>
            <Fade >
              <ItemWrapper title="Website Optimization">
                <Typography>
                  From enhancing user experience to optimizing page load times, we leave no stone unturned. Whether it's refining content,
                  streamlining navigation, or fine-tuning technical aspects, our team of experts ensures that every element of your website
                  is optimized for maximum efficiency and impact.
                </Typography>
                <Typography>
                  At Simplex, we understand that a website is not just an online presence, but a dynamic platform for engagement and conversion.
                  Our holistic approach to website optimization is designed to drive results, providing your brand with the digital edge it
                  deserves. Elevate your online presence with Simplex and experience the transformative power of strategic website optimization.
                </Typography>
              </ItemWrapper>
            </Fade>
          </Stack>
        </Grid>
        <Grid item sm={12} md={6}>
          <Stack spacing={4} sx={{ mt: { sm : 0, md: 8 } }}>
            <Fade >
              <ItemWrapper title="Digital Programmatic">
              <Typography>
                In the realm of paid media, every dollar counts. At Simplex Media, we pride ourselves on a data-driven approach
                that ensures not a single ad spend dollar goes to waste. Through meticulous audience data collection, we pinpoint
                the optimal channels to target your audience effectively. Whether it's leveraging the power of Google, harnessing
                the potential of Programmatic advertising, or exploring dynamic Out of Home options, Simplex Media presents the solution.
              </Typography>
              <Typography>
                Our process is defined by four key stages: Strategize, Execute, Optimize, and Report. We craft a bespoke
                strategy tailored to your unique objectives, execute campaigns with precision, continuously optimize for peak
                performance, and provide detailed reports for transparency and accountability. Throughout this journey, we prioritize
                budget maximization, ensuring that your investments yield the highest returns. Trust Simplex Media to navigate the
                paid media space and elevate your brand's visibility and impact. Your success is our commitment.
              </Typography>
            </ItemWrapper>
            </Fade>
            <Fade >
              <ItemWrapper title="Lead Generation">
                <Typography>
                  Through targeted campaigns and data-driven analysis, we identify and engage with high-quality leads that align with your business objectives.
                  Our comprehensive approach involves crafting compelling content, implementing effective email marketing, and leveraging social media platforms
                  to create a robust lead pipeline. Through targeted campaigns and data-driven analysis, we identify and engage with high-quality leads that
                  align with your business objectives. Our comprehensive approach involves crafting compelling content, implementing effective email marketing,
                  and leveraging social media platforms to create a robust lead pipeline.
                </Typography>
              </ItemWrapper>
            </Fade>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}